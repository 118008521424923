import { Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { AppUser } from "../types";
import C4cAvatar from "./C4cAvatar";

interface AvatarUploadProps {
  user: AppUser;
  setAvatar: (avatarUrl: string) => void;
}

type UploadResponse = {
  event: string;
  info: {
    secure_url: string;
  };
};

const AvatarUpload = ({ user, setAvatar }: AvatarUploadProps) => {
  const showWidget = () => {
    //@ts-ignore
    let widget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        cropping: true,
        multiple: false,
        folder: "user_avatars",
        clientAllowedFormats: ["image"],
        maxImageFileSize: 2000000,
      },
      (error: unknown, result: UploadResponse) => {
        if (!error && result && result.event === "success") {
          setAvatar(result.info.secure_url);
        }
      }
    );

    widget.open();
  };

  return (
    <Box
      onClick={showWidget}
      sx={{ position: "relative", "&:hover .overlay": { display: "flex" } }}
    >
      <Box
        className="overlay"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
          display: "none",
          cursor: "pointer",
          backgroundColor: "rgba(0,0,0,0.3)",
          borderRadius: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EditIcon sx={{ color: "white" }} />
      </Box>
      <C4cAvatar user={user} />
    </Box>
  );
};

export default AvatarUpload;
