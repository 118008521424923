import { Card, CardContent, CardMedia, Fab, Typography } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { CharityDetail } from '../types'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'

interface MyCharityCardProps {
  myCharity: CharityDetail
}

const MyCharityCard = ({ myCharity }: MyCharityCardProps) => {
  const nav = useNavigate()
  const handleClick = () => {
    nav(`/charities/${myCharity.charityId}`)
  }
  return (
    <Card onClick={handleClick}>
      <CardMedia
        component="img"
        image={`${process.env.PUBLIC_URL}/images/${myCharity.media.header}`}
        sx={{ maxHeight: '400px' }}
      />

      <CardContent sx={{ position: 'relative', p: 3 }}>
        <Fab color="secondary" sx={{ position: 'absolute', top: -40 }}>
          <FavoriteIcon sx={{ color: '#fff' }} />
        </Fab>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <Typography variant="h6">Your Charity</Typography>
          {myCharity.verticalLogo ? (
            <img
              src={`${process.env.PUBLIC_URL}/images/${myCharity.media.logo}`}
              style={{ height: 140, position: 'absolute', right: 0, top: -100 }}
              alt="charity logo"
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/images/${myCharity.media.logo}`}
              style={{ height: 40 }}
              alt="charity logo"
            />
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default MyCharityCard
