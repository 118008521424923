const isDev = process.env.NODE_ENV === 'development'

export const UserApiBasePath = isDev
  ? '/users'
  : `${process.env.REACT_APP_USER_BASE_PATH}/users`

export const CharitiesApiBasePath = isDev
  ? '/charities'
  : `${process.env.REACT_APP_CHARITY_BASE_PATH}/charities`

export const TransactionsApiBasePath = isDev
  ? '/transactions'
  : `${process.env.REACT_APP_TRANSACTION_BASE_PATH}/transactions`
