import { Box, Typography } from '@mui/material'

interface StatItemProps {
  stat: string
  underline: string
}

const StatItem = ({ stat, underline }: StatItemProps) => (
  <Box>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
      {stat}
    </Typography>
    <Typography variant="subtitle2" sx={{ color: 'secondary.main' }}>
      {underline}
    </Typography>
  </Box>
)

export default StatItem
