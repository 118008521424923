import { AppUser } from "../types";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import GradientDivider from "./GradientDivider";
import { useMutation, useQueryClient } from "react-query";
import {
  BasiqUserMutationProps,
  upsertBasiqUserQuery,
} from "../hooks/useBasiqUserMutation";
import { LoadingButton } from "@mui/lab";
import { useAuth0 } from "@auth0/auth0-react";
import {
  updateUserQuery,
  UpdateUserQueryProps,
} from "../hooks/useUserMutation";
import * as yup from "yup";
import { useFormik } from "formik";
import BasiqIframe from "./BasiqIframe";
import IOSSwitch from "./IOSSwitch";

type BasiqModalProps = {
  user: AppUser;
  open: boolean;
  handleClose: () => void;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  fullName: yup.string().required("Please enter your full name"),
  roundUpWholeDollars: yup.boolean(),
  hasMonthlyCap: yup.boolean(),
  monthlyCap: yup
    .number()
    .integer()
    .when("hasMonthlyCap", {
      is: true,
      then: yup
        .number()
        .integer()
        .required("Please enter a monthly cap")
        .min(5, "Please enter a monthly cap of at least $5"),
    }),
});

const BasiqModal = (props: BasiqModalProps) => {
  const { user, open, handleClose } = props;
  const { basiqUserId, email, fullName } = user;
  const { user: authUser, getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const useBasiqUserMutation = useMutation(
    ({ email, authUser, token, fullName }: BasiqUserMutationProps) =>
      upsertBasiqUserQuery({
        email,
        authUser,
        token,
        fullName,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries("basiqToken");
      },
    }
  );

  const useUserMutation = useMutation(
    ({ user, authUser, token }: UpdateUserQueryProps) =>
      updateUserQuery({ user, authUser, token }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
    }
  );

  const onClose = () => {
    const event = new Event("jobCreated");
    window.dispatchEvent(event);
    console.log("close");
    handleClose();
  };

  const setupBasiq = async (
    userEmail: string,
    userName: string,
    roundUpWholeDollars: boolean,
    hasMonthlyCap: boolean,
    monthlyCap: number
  ) => {
    if (!basiqUserId && authUser) {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
        },
      });

      if (!email || !fullName) {
        console.log("didn't have everything I needed");
        useUserMutation.mutate({
          user: {
            ...user,
            email: userEmail,
            fullName: userName,
            wholeDollarRoundups: roundUpWholeDollars,
            donationCap: hasMonthlyCap ? monthlyCap : user.donationCap,
          },
          authUser,
          token,
        });
        useBasiqUserMutation.mutate({
          email: userEmail,
          fullName: userName,
          authUser,
          token,
        });
      } else {
        console.log("did have everything I needed");
        useUserMutation.mutate({
          user: {
            ...user,
            wholeDollarRoundups: roundUpWholeDollars,
            donationCap: hasMonthlyCap ? monthlyCap : user.donationCap,
          },
          authUser,
          token,
        });
        useBasiqUserMutation.mutate({
          email,
          fullName,
          authUser,
          token,
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: email ?? "",
      fullName: fullName ?? "",
      roundUpWholeDollars: true,
      hasMonthlyCap: false,
      monthlyCap: 250,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("consent modal submit");
      console.log(values);
      setupBasiq(
        values.email,
        values.fullName,
        values.roundUpWholeDollars,
        values.hasMonthlyCap,
        values.monthlyCap
      ).then((_) => {
        formik.setSubmitting(false);
      });
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card sx={{ maxWidth: 450, position: "relative" }}>
          <CardHeader
            action={
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            }
            title={
              basiqUserId ? "Connect Accounts" : "Configure your round-ups"
            }
          />
          <CardContent>
            {!basiqUserId && (
              <form onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>
                  {!email && (
                    <Typography>
                      So we can send you your deductable gift receipts for your
                      donations we'll need your email address.
                    </Typography>
                  )}
                  <TextField
                    id="email"
                    label="email"
                    value={formik.values.email}
                    placeholder="Your email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="standard"
                    fullWidth
                    style={{ display: email ? "none" : "initial" }}
                  />

                  {!fullName && (
                    <Typography>
                      So we can identify you for tax purposes we'll need your
                      full name.
                    </Typography>
                  )}

                  <TextField
                    id="fullName"
                    label="fullName"
                    value={formik.values.fullName}
                    placeholder="Your full name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                    variant="standard"
                    fullWidth
                    style={{ display: fullName ? "none" : "initial" }}
                  />

                  <List>
                    <ListItem
                    // sx={{
                    //   borderBottom: (theme) =>
                    //     `1px solid ${theme.palette.divider}`,
                    // }}
                    >
                      <ListItemText
                        primary="Round Up Whole Dollars"
                        secondary="eg. $50 transaction rounds to $51, with $1 going to your charity"
                      />
                      <IOSSwitch
                        id="roundUpWholeDollars"
                        checked={formik.values.roundUpWholeDollars}
                        onChange={formik.handleChange}
                        name="roundUpWholeDollars"
                        onBlur={formik.handleBlur}
                      />
                    </ListItem>
                    <ListItem
                      // sx={{
                      //   borderBottom: (theme) =>
                      //     `1px solid ${theme.palette.divider}`,
                      // }}
                      secondaryAction={
                        <IOSSwitch
                          id="hasMonthlyCap"
                          checked={formik.values.hasMonthlyCap}
                          onChange={formik.handleChange}
                          name="hasMonthlyCap"
                          onBlur={formik.handleBlur}
                        />
                      }
                    >
                      <Stack spacing={0} sx={{ width: "100%" }}>
                        <ListItemText primary="Monthly Donation Limit" />
                        <TextField
                          id="monthlyCap"
                          value={formik.values.monthlyCap}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.monthlyCap &&
                            Boolean(formik.errors.monthlyCap)
                          }
                          helperText={
                            formik.touched.monthlyCap &&
                            formik.errors.monthlyCap
                          }
                          variant="standard"
                          fullWidth
                          type="number"
                          style={{
                            display: !formik.values.hasMonthlyCap
                              ? "none"
                              : "initial",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </ListItem>
                  </List>

                  <Typography>
                    Remember, you can pause your donations at any time.
                  </Typography>
                  <GradientDivider />
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ px: 5, py: 2 }}
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    fullWidth
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </form>
            )}
            {basiqUserId && (
              <Card
                variant="outlined"
                sx={{ maxHeight: "80vh", overflow: "auto" }}
              >
                <CardContent>
                  <BasiqIframe user={user} />
                </CardContent>
              </Card>
            )}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default BasiqModal;
