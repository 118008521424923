import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
  Zoom,
  Button,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import { useEffect, useState } from 'react'
import NavFooter from '../components/NavFooter'
import ScreenHeader from '../components/ScreenHeader'
import StatItem from '../components/StatItem'
import IOSSwitch from '../components/IOSSwitch'
import useUser from '../hooks/useUser'
import { Account } from '../types'
import { useMutation, useQueryClient } from 'react-query'
import { updateUserQuery, UpdateUserQueryProps } from '../hooks/useUserMutation'
import {
  updateUserDonationQuery,
  UpdateUserDonationQueryProps,
} from '../hooks/useUserDonationMutation'
import CentralModal from '../components/CentralModal'
//import ChangePasswordModal from '../components/ChangePasswordModal'
import AvatarUpload from '../components/AvatarUpload'
import BasiqModal from '../components/BasiqModal'
import BasiqJobHandler from '../components/BasiqJobHandler'
import { getDonationHistoryForDate } from '../utils/DonationHistory'
import * as fns from 'date-fns'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { useAuth0 } from '@auth0/auth0-react'
import useUserRefresh from '../hooks/useUserRefresh'

enum duration {
  week,
  fortnight,
  month,
}

let token = ''

const ProfilePage: React.FC = () => {
  const { logout, user: authUser, getAccessTokenSilently } = useAuth0()
  useUserRefresh()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const queryClient = useQueryClient()
  const { data: user, isLoading } = useUser()
  const [pausedOpen, setPausedOpen] = useState(false)
  const handlePausedClosed = () => setPausedOpen(false)
  const [selectedPaused, setSelectedPaused] = useState<duration>()
  const [donCapOpen, setDonCapOpen] = useState(false)
  const handleDonCapClose = () => setDonCapOpen(false)
  const [donCap, setDonCap] = useState<number>()
  //const [changePassVisible, setChangePassVisible] = useState(false)
  //const handleChangePassClose = () => setChangePassVisible(false)

  const [basiqModalOpen, setBasiqModalOpen] = useState(false)
  const handleBasiqModalClose = () => setBasiqModalOpen(false)

  const oneWeek = fns.addDays(new Date(), 7)
  const oneFortnight = fns.addDays(new Date(), 14)
  const oneMonth = fns.addDays(new Date(), 28) // technically four weeks not a month

  useEffect(() => {
    if (!isLoading && user) {
      setDonCap(user.donationCap)
    }
  }, [user, isLoading])

  const fetchToken = async () => {
    if (token) {
      return token
    } else {
      token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
        },
      })
      return token
    }
  }

  const useUserMutation = useMutation(
    ({ user, authUser, token }: UpdateUserQueryProps) =>
      updateUserQuery({ user, authUser, token }),
    {
      onSuccess: () => queryClient.invalidateQueries('users'),
    },
  )

  const useUserDonationMutation = useMutation(
    ({ accountId, authUser, token }: UpdateUserDonationQueryProps) =>
      updateUserDonationQuery({ accountId, authUser, token }),
  )

  async function toggleAccount(value: boolean, account: Account) {
    if (!isLoading && user && user.accounts && authUser) {
      const t = await fetchToken()
      let uAccounts = user.accounts
      const i = uAccounts.findIndex((a) => a.accountId === account.accountId)
      uAccounts[i].active = value

      useUserMutation.mutate({
        user: {
          ...user,
          accounts: uAccounts,
        },
        authUser,
        token: t,
      })
    }
  }

  async function toggleTransferCosts(value: boolean) {
    if (!isLoading && user && authUser) {
      const t = await fetchToken()

      useUserMutation.mutate({
        user: {
          ...user,
          coverTransferCosts: value,
        },
        authUser,
        token: t,
      })
    }
  }

  async function toggleWholeRoundups(value: boolean) {
    if (!isLoading && user && authUser) {
      const t = await fetchToken()

      useUserMutation.mutate({
        user: {
          ...user,
          wholeDollarRoundups: value,
        },
        authUser,
        token: t,
      })
    }
  }

  async function toggleDisbursementAccount(accountId: string) {
    if (!isLoading && user && user.accounts && authUser) {
      const t = await fetchToken()
      useUserMutation.mutate({
        user: {
          ...user,
          disbursementAccount: accountId,
        },
        authUser,
        token: t,
      })
      useUserDonationMutation.mutate({ accountId, authUser, token })
    }
  }

  async function updateDonationsPaused() {
    if (!isLoading && user && authUser) {
      let pausedTill
      switch (selectedPaused) {
        case duration.week:
          pausedTill = fns.format(oneWeek, 'yyyy-MM-dd')
          break

        case duration.fortnight:
          pausedTill = fns.format(oneFortnight, 'yyyy-MM-dd')
          break

        case duration.month:
          pausedTill = fns.format(oneMonth, 'yyyy-MM-dd')
          break
      }
      const t = await fetchToken()
      useUserMutation.mutate({
        user: {
          ...user,
          donationsPaused: !user.donationsPaused,
          //@ts-ignore
          donationsPausedTill: pausedTill,
        },
        authUser,
        token: t,
      })
      setPausedOpen(false)
    }
  }

  async function tryUpdateDonCap() {
    if (!isLoading && user && authUser) {
      if (donCap) {
        let updateCap = donCap
        if (updateCap < 10) {
          setDonCap(10)
          updateCap = 10
        }

        const t = await fetchToken()
        useUserMutation.mutate({
          user: {
            ...user,
            donationCap: updateCap,
          },
          authUser,
          token: t,
        })
        handleDonCapClose()
      }
    }
  }

  async function tryUpdateAvatar(avatarUrl: string) {
    if (!isLoading && user && authUser) {
      const t = await fetchToken()
      useUserMutation.mutate({
        user: {
          ...user,
          avatarUrl,
        },
        authUser,
        token: t,
      })
    }
  }

  const monthDonations = getDonationHistoryForDate(
    user?.donationHistory,
    new Date(),
  )

  return (
    <Box sx={{ pb: 12 }}>
      <ScreenHeader title="Profile" />
      <Container maxWidth="sm" sx={{ p: { xs: 0 } }}>
        <Box
          sx={{
            pt: 2,
            mb: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {user && <AvatarUpload user={user} setAvatar={tryUpdateAvatar} />}
          <Typography sx={{ ml: 2 }} variant="h5">
            {user?.fullName}
          </Typography>
        </Box>
        <Card>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <StatItem
                stat={`$${monthDonations.toFixed(2)}`}
                underline="This months donations"
              />
              <StatItem
                stat={`$${user?.allTimeDonations || 0.0}`}
                underline="All time donations"
              />
            </Box>

            <List
              subheader={
                <ListSubheader sx={{ pl: 0 }}>
                  Linked Accounts
                  {!isSmallScreen && (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Toggle any account you want round ups enabled on"
                    >
                      <InfoIcon sx={{ ml: 1, fontSize: '16px' }} />
                    </Tooltip>
                  )}
                  {isSmallScreen && (
                    <Typography
                      variant="body2"
                      sx={{ marginTop: '-14px', fontSize: '0.75rem' }}
                    >
                      Toggle any account you want round ups enabled on
                    </Typography>
                  )}
                </ListSubheader>
              }
            >
              {user?.accounts?.map((account) => (
                <ListItem
                  key={account.accountId}
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ListItemText primary={account.accountName} />
                  <IOSSwitch
                    edge="end"
                    checked={account.active}
                    onChange={(_, checked) => toggleAccount(checked, account)}
                  />
                </ListItem>
              ))}
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => setBasiqModalOpen(true)}
                startIcon={<AccountBalanceWalletIcon />}
                sx={{ mt: 1 }}
              >
                Add Accounts
              </Button>
            </List>

            {user?.accounts && (
              <List
                subheader={
                  <ListSubheader sx={{ pl: 0 }}>
                    Donation Account
                    {!isSmallScreen && (
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="The account donations are drawn from"
                      >
                        <InfoIcon sx={{ ml: 1, fontSize: '16px' }} />
                      </Tooltip>
                    )}
                    {isSmallScreen && (
                      <Typography
                        variant="body2"
                        sx={{ marginTop: '-14px', fontSize: '0.75rem' }}
                      >
                        The account donations are drawn from
                      </Typography>
                    )}
                  </ListSubheader>
                }
              >
                <TextField
                  variant="standard"
                  select
                  value={user?.disbursementAccount}
                  onChange={(ev) => toggleDisbursementAccount(ev.target.value)}
                  fullWidth
                >
                  {user?.accounts?.map((account) => (
                    <MenuItem key={account.accountId} value={account.accountId}>
                      {account.accountName}
                    </MenuItem>
                  ))}
                </TextField>
              </List>
            )}

            <List
              subheader={
                <ListSubheader sx={{ pl: 0 }}>Donation Settings</ListSubheader>
              }
            >
              {user && (
                <ListItem
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="body1" component="span">
                          Donation transfer fee
                        </Typography>
                        <Tooltip
                          TransitionComponent={Zoom}
                          title="When your donations reach $10 there’s a transaction fee of up to 25c to send it to your charity. If you help cover it they don’t have to which means more impact, so thank you."
                        >
                          <InfoIcon sx={{ ml: 1, fontSize: '16px' }} />
                        </Tooltip>
                      </>
                    }
                  />
                  <IOSSwitch
                    edge="end"
                    checked={user.coverTransferCosts}
                    onChange={(_, checked) => toggleTransferCosts(checked)}
                  />
                </ListItem>
              )}
              {user && (
                <ListItem
                  sx={{
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="body1" component="span">
                          Round Up whole dollars
                        </Typography>
                        <Tooltip
                          TransitionComponent={Zoom}
                          title="Round Up one dollar on purchases that equal a whole dollar amount eg. $50 rounds to $51, with $1 going to your charity."
                        >
                          <InfoIcon sx={{ ml: 1, fontSize: '16px' }} />
                        </Tooltip>
                      </>
                    }
                  />
                  <IOSSwitch
                    edge="end"
                    checked={user.wholeDollarRoundups ?? false}
                    onChange={(_, checked) => toggleWholeRoundups(checked)}
                  />
                </ListItem>
              )}
              <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="change monthly donation limit"
                    onClick={() => setDonCapOpen(true)}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary="Monthly donation limit" />
              </ListItem>
              <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="change monthly donation limit"
                    onClick={() => setPausedOpen(true)}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    user?.donationsPaused
                      ? `Donations paused until ${fns.format(
                          new Date(user.donationsPausedTill),
                          'do MMM y',
                        )}`
                      : 'Pause donations'
                  }
                />
              </ListItem>
            </List>

            <CentralModal open={pausedOpen} handleClose={handlePausedClosed}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mr: -1,
                }}
              >
                <Typography variant="h6">
                  {user?.donationsPaused
                    ? `Donations paused until ${fns.format(
                        new Date(user.donationsPausedTill),
                        'do MMM y',
                      )}`
                    : 'Pause donations for'}
                </Typography>
                <IconButton onClick={handlePausedClosed}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {!user?.donationsPaused && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    //mr: -1,
                  }}
                >
                  <Card
                    sx={{
                      width: '30%',
                      border:
                        selectedPaused === duration.week
                          ? (theme) =>
                              `solid 1px ${theme.palette.secondary.main}`
                          : 'solid 1px transparent',
                    }}
                    onClick={() => setSelectedPaused(duration.week)}
                  >
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="body1">1 week</Typography>
                      <Typography variant="caption" component="p">
                        {fns.format(oneWeek, 'do MMM y')}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      width: '30%',
                      border:
                        selectedPaused === duration.fortnight
                          ? (theme) =>
                              `solid 1px ${theme.palette.secondary.main}`
                          : 'solid 1px transparent',
                    }}
                    onClick={() => setSelectedPaused(duration.fortnight)}
                  >
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="body1">2 weeks</Typography>
                      <Typography variant="caption" component="p">
                        {fns.format(oneFortnight, 'do MMM y')}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      width: '30%',
                      border:
                        selectedPaused === duration.month
                          ? (theme) =>
                              `solid 1px ${theme.palette.secondary.main}`
                          : 'solid 1px transparent',
                    }}
                    onClick={() => setSelectedPaused(duration.month)}
                  >
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="body1">4 weeks</Typography>
                      <Typography variant="caption" component="p">
                        {fns.format(oneMonth, 'do MMM y')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              )}
              <Button
                variant="contained"
                sx={{ p: 2 }}
                fullWidth
                disabled={
                  isLoading ||
                  (selectedPaused === undefined && !user?.donationsPaused)
                }
                onClick={updateDonationsPaused}
              >
                {user?.donationsPaused ? 'Resume donations' : 'Pause donations'}
              </Button>
            </CentralModal>

            <CentralModal open={donCapOpen} handleClose={handleDonCapClose}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mr: -1,
                }}
              >
                <Typography variant="h6">
                  Change monthly donation limit
                </Typography>
                <IconButton onClick={handleDonCapClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <TextField
                variant="standard"
                type="number"
                fullWidth
                label="Current donation limit"
                value={donCap}
                onChange={(ev) => setDonCap(parseInt(ev.target.value))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                sx={{ p: 2 }}
                fullWidth
                disabled={isLoading || user?.donationCap === donCap || !donCap}
                onClick={tryUpdateDonCap}
              >
                Update Donation Limit
              </Button>
            </CentralModal>

            <List
              subheader={
                <ListSubheader sx={{ pl: 0 }}>My Settings</ListSubheader>
              }
            >
              {/* <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="change password"
                    onClick={() => setChangePassVisible(true)}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary="Change password" />
              </ListItem> */}
              <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="FAQ link"
                    onClick={() =>
                      window
                        .open('http://www.change4change.com.au/faq', '_blank')!
                        .focus()
                    }
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary="FAQ" />
              </ListItem>
              <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="terms and conditions link"
                    onClick={() =>
                      window
                        .open(
                          'https://www.iubenda.com/terms-and-conditions/43048979',
                          '_blank',
                        )!
                        .focus()
                    }
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary="Terms & Conditions" />
              </ListItem>
              <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="privacy policy link"
                    onClick={() =>
                      window
                        .open(
                          'https://www.iubenda.com/privacy-policy/43048979',
                          '_blank',
                        )!
                        .focus()
                    }
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary="Privacy policy" />
              </ListItem>
              <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="cookie policy link"
                    onClick={() =>
                      window
                        .open(
                          'https://www.iubenda.com/privacy-policy/43048979/cookie-policy',
                          '_blank',
                        )!
                        .focus()
                    }
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary="Cookie policy" />
              </ListItem>
              <ListItem
                sx={{
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="log out"
                    onClick={() => {
                      queryClient.invalidateQueries()
                      logout()
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                }
              >
                <ListItemText primary="Log Out" />
              </ListItem>
            </List>

            {/* <ChangePasswordModal
              open={changePassVisible}
              handleClose={handleChangePassClose}
            /> */}

            {user && (
              <BasiqModal
                open={basiqModalOpen}
                handleClose={handleBasiqModalClose}
                user={user}
              />
            )}
          </CardContent>
        </Card>
      </Container>
      <NavFooter />
      <BasiqJobHandler />
    </Box>
  )
}

export default ProfilePage
