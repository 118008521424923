import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router";
import { AppUser } from "../types";

interface OnboardingCardProps {
  user: AppUser;
  connectAccounts: () => void;
}

const OnboardingCard = ({ user, connectAccounts }: OnboardingCardProps) => {
  const hasAccounts = (user.accounts?.length ?? 0) > 0;
  const hasCharity = !!user.myCharity;
  const canSelectCharity = hasAccounts && !hasCharity;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const nav = useNavigate();
  const handleCharitiesClick = () => {
    nav("/charities");
  };

  const handleConnectAccountsClick = () => {
    connectAccounts();
  };
  return (
    <Card>
      <CardHeader title="Start making change" />
      <CardContent sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {hasAccounts ? (
                      <CheckCircleIcon color="secondary" />
                    ) : (
                      <RadioButtonUncheckedIcon color="disabled" />
                    )}
                    <Typography variant="h6" sx={{ pl: 1 }}>
                      Connect your bank account
                    </Typography>
                  </Box>
                </Grid>
                {!hasAccounts && !mobile && (
                  <Grid item xs={0} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleConnectAccountsClick}
                    >
                      Connect
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={10}>
                  <Typography variant="body1">
                    We'll use this to round-up your transactions and donate it
                    to your chosen charity.
                  </Typography>
                </Grid>
                {!hasAccounts && mobile && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleConnectAccountsClick}
                    >
                      Connect
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={10}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {hasCharity ? (
                      <CheckCircleIcon color="secondary" />
                    ) : (
                      <RadioButtonUncheckedIcon color="disabled" />
                    )}
                    <Typography variant="h6" sx={{ pl: 1 }}>
                      Select your charity
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  {!hasCharity && !mobile && canSelectCharity ? (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleCharitiesClick}
                    >
                      Select
                    </Button>
                  ) : (
                    <LockIcon color="disabled" />
                  )}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography variant="body1">
                    Choose from one of our leading charities and contribute to a
                    cause you're passionate about.
                  </Typography>
                </Grid>
                {!hasCharity && canSelectCharity && mobile && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleCharitiesClick}
                    >
                      Select
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OnboardingCard;
