import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import useBasiqToken from '../hooks/useBasiqToken'
import {
  BasiqUserAccountsMutationProps,
  upsertBasiqUserAccountsQuery,
} from '../hooks/useBasiqUserAccountsMutation'
import { useInterval } from '../hooks/useInterval'
import useUser from '../hooks/useUser'
import {
  fetchBasiqJob,
  fetchBasiqJobsForUser,
  JobStepStatus,
  JobStepTitle,
} from '../services/BasiqService'

let authToken = ''
let fetching = false

const BasiqJobHandler = () => {
  const queryClient = useQueryClient()
  const { data: user } = useUser()
  const { user: authUser, getAccessTokenSilently } = useAuth0()
  const { data: token, isLoading: tokenLoading } = useBasiqToken()
  const [jobId, setJobId] = useState('')
  const [fetchingJobs, setFetchingJobs] = useState(false)
  const [fetchingAccounts, setFetchingAccounts] = useState(false)

  const fetchToken = async () => {
    if (authToken) {
      return authToken
    } else {
      authToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
        },
      })
      return authToken
    }
  }

  const useBasiqUserAccountMutation = useMutation(
    ({ authUser, token }: BasiqUserAccountsMutationProps) =>
      upsertBasiqUserAccountsQuery({ authUser, token }),
    {
      onSuccess: () => {
        setJobId('')
        setFetchingAccounts(false)
        queryClient.invalidateQueries('users')
      },
      onError: (err) => {
        console.log(err)
        setJobId('')
        setFetchingAccounts(false)
      },
    },
  )

  useInterval(async () => {
    if (jobId && !tokenLoading && token) {
      console.log('checking job')
      const jobStatus = await fetchBasiqJob(jobId, token)

      console.log(jobStatus)

      const credsValidStep = jobStatus.steps.find(
        (j) => j.title === JobStepTitle.verifyCredentials,
      )
      const accountsFetchedStep = jobStatus.steps.find(
        (j) => j.title === JobStepTitle.retrieveAccounts,
      )
      if (
        credsValidStep?.status === JobStepStatus.success &&
        accountsFetchedStep?.status === JobStepStatus.success
      ) {
        if (!fetchingAccounts && authUser) {
          const t = await fetchToken()
          useBasiqUserAccountMutation.mutate({
            authUser,
            token: t,
          })
          setFetchingAccounts(true)
        }
      } else if (credsValidStep?.status === JobStepStatus.failed) {
        console.log('credential error')
        setJobId('')
      } else if (accountsFetchedStep?.status === JobStepStatus.failed) {
        console.log('Unable to fetch accounts')
        setJobId('')
      }
    }
  }, 2000)

  const fetchJobs = async () => {
    if (user?.basiqUserId && !jobId && !fetchingJobs) {
      console.log('fetching jobs')
      setFetchingJobs(true)
      const t = await fetchToken()
      const basiqJobs = await fetchBasiqJobsForUser(user?.basiqUserId, t)
      console.log(basiqJobs)
      if (basiqJobs.length > 0) {
        console.log(basiqJobs[0].id)
        setJobId(basiqJobs[0].id)
      }
      fetching = false
    }
  }

  const handleNewJob = () => {
    if (user?.basiqUserId && !jobId && !fetchingJobs && !fetching) {
      console.log('handling new job')
      fetching = true
      setFetchingJobs(true)
      fetchJobs()
    }
  }

  window.addEventListener('jobCreated', handleNewJob)

  return <></>
}

export default BasiqJobHandler
