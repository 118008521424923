import useBasiqToken from "../hooks/useBasiqToken";
import { AppUser } from "../types";

type BasiqIframeProps = {
  user: AppUser;
};

const BasiqIframe = (props: BasiqIframeProps) => {
  const { basiqUserId } = props.user;
  const { data: token, isLoading: tokenLoading } = useBasiqToken();
  const action = basiqUserId ? "connect" : "consent";
  console.log("basiq user id: " + basiqUserId);

  return (
    <div style={{ height: 600, width: 350 }}>
      {token && !tokenLoading && (
        <iframe
          title="Basiq Connection"
          src={`https://consent.basiq.io/home?&token=${token}&action=${action}`}
          height={600}
          width={350}
          style={{ border: "none" }}
        />
      )}
    </div>
  );
};

export default BasiqIframe;
