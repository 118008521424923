import {
  Button,
  Card,
  CardContent,
  //Checkbox,
  //Drawer,
  Fab,
  List,
  //ListItem,
  //ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  //IconButton,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router";
import useCharities from "../hooks/useCharities";
import useUser from "../hooks/useUser";
import ReactPlayer from "react-player";
import { Masonry } from "@mui/lab";
import GradientDivider from "../components/GradientDivider";
import ImpactItem from "../components/ImpactItem";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CharitySocialGroup from "../components/CharitySocialGroup";
//import CloseIcon from '@mui/icons-material/Close'
//import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from "react-query";
import {
  updateUserQuery,
  UpdateUserQueryProps,
} from "../hooks/useUserMutation";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import UNGoalsCard from "../components/UNGoalsCard";
import { useAuth0 } from "@auth0/auth0-react";
import useUserRefresh from "../hooks/useUserRefresh";

type RouteParams = {
  charityId: string;
};

const CharityDetailsPage = () => {
  const { user: authUser, getAccessTokenSilently } = useAuth0();
  useUserRefresh();
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up("sm"));

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { charityId } = useParams<keyof RouteParams>() as RouteParams;
  const { data: charities, isLoading } = useCharities();
  const { data: user } = useUser();

  const charity = charities?.find((c) => c.charityId === charityId);
  const myCharity = user?.myCharity === charity?.charityId;

  const useUserMutation = useMutation(
    ({ user, authUser, token }: UpdateUserQueryProps) =>
      updateUserQuery({ user, authUser, token }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
    }
  );

  // const [visible, setVisible] = useState(false)

  // const toggleDrawer = () => setVisible(!visible)

  //const [pickedGoal, setPickedGoal] = useState<string>()

  // useEffect(() => {
  //   if (user && user.charityGoal) {
  //     setPickedGoal(user.charityGoal)
  //   }
  // }, [user])

  const setCharityGoal = async () => {
    if (charity && charity.goals && authUser) {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
        },
      });
      useUserMutation.mutate({
        user: {
          ...user!,
          myCharity: charity.charityId,
          charityGoal: charity.goals[0].goalId,
        },
        authUser,
        token,
      });
    }
    //setVisible(false)
    navigate("/home");
  };

  const goBack = () => navigate(-1);

  return (
    <Box>
      {!isLoading && charity && (
        <>
          <Box sx={{ position: "fixed", zIndex: 1 }}>
            <Container maxWidth="lg" sx={{ height: 80 }}>
              <Tooltip title="Go back" placement="right">
                <Fab
                  color="primary"
                  sx={{ position: "relative", mt: 3 }}
                  onClick={goBack}
                >
                  <ArrowBackIosNewIcon sx={{ color: "#fff" }} />
                </Fab>
              </Tooltip>
            </Container>
          </Box>
          <Card
            elevation={4}
            sx={{
              minHeight: "95vh",
              p: notMobile ? 3 : 1,
              mt: 4,
              mb: 4,
              position: "relative",
            }}
          >
            <Card
              sx={{
                position: "relative",
                pt: "56.25%",
              }}
            >
              <ReactPlayer
                style={{ position: "absolute", top: 0, left: 0 }}
                url={charity.media.headerVideo}
                controls={true}
                width="100%"
                height="100%"
              />
            </Card>
            <CardContent sx={{ pl: 0, pr: 0, pt: 3, pb: 3 }}>
              <Masonry
                columns={notMobile ? 2 : 1}
                spacing={3}
                sx={{ margin: 0 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/${charity.media.logo}`}
                    style={{ maxWidth: charity.verticalLogo ? "30%" : "60%" }}
                    alt="charity logo"
                  />

                  <CharitySocialGroup social={charity.social} />

                  <Card sx={{ mt: 3 }}>
                    <CardContent
                      sx={{
                        p: notMobile ? 3 : 1,
                        "&:last-child": { pb: 0 },
                      }}
                    >
                      {charity.about.map((a, i) => (
                        <Typography key={i} variant="body1" sx={{ pb: 2 }}>
                          {a}
                        </Typography>
                      ))}
                      <GradientDivider sx={{ mt: 1 }} />
                      <Typography
                        variant="h6"
                        sx={{ mt: 2, textAlign: "center" }}
                      >
                        Our Impact
                      </Typography>
                      <List>
                        {charity.impacts.map((impact, index) => (
                          <ImpactItem key={index} impact={impact} />
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </Box>

                <Box sx={{ position: "relative" }}>
                  {myCharity && (
                    <Tooltip title="My Charity" placement="right">
                      <Fab
                        color="primary"
                        size={notMobile ? "large" : "small"}
                        sx={{
                          position: "absolute",
                          top: -22,
                          left: notMobile ? -26 : -12,
                        }}
                      >
                        <FavoriteIcon sx={{ color: "#fff" }} />
                      </Fab>
                    </Tooltip>
                  )}
                  <Card>
                    <Box
                      sx={{
                        p: notMobile ? 3 : 1,
                      }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        color="primary"
                        sx={{ p: 2, color: "#fff", fontWeight: "bold" }}
                        startIcon={
                          myCharity ? <AutorenewIcon /> : <FavoriteIcon />
                        }
                        onClick={setCharityGoal}
                      >
                        {myCharity ? "My Charity" : "Select Charity"}
                      </Button>
                    </Box>
                  </Card>
                </Box>

                <Card
                  sx={{
                    pt: "56.25%",
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/${charity.media.header})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></Card>

                {charity.social.twitter && (
                  <Card sx={{ maxHeight: 750, overflow: "auto" }}>
                    <TwitterTimelineEmbed
                      sourceType="profile"
                      screenName={charity.social.twitter.substring(
                        charity.social.twitter.lastIndexOf("/") + 1
                      )}
                      options={{
                        height: 600,
                      }}
                    />
                  </Card>
                )}
                {charity.susDevGoals && (
                  <UNGoalsCard ungoals={charity.susDevGoals} />
                )}
              </Masonry>
            </CardContent>
          </Card>
          {/* <Drawer
            anchor={notMobile ? 'right' : 'bottom'}
            open={visible}
            onClose={toggleDrawer}
            PaperProps={{
              sx: {
                width: { xs: 'auto', sm: '60vw', md: '50vw', lg: '30vw' },
              },
            }}
          >
            <Box sx={{ p: 3, position: 'relative' }}>
              <IconButton
                onClick={toggleDrawer}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
              <List>
                {charity.goals?.map((goal, index) => {
                  const selected = goal.goalId === pickedGoal
                  return (
                    <ListItem
                      key={index}
                      sx={{
                        backgroundColor: selected
                          ? '#fff'
                          : (theme) => theme.palette.divider,
                        borderRadius: '16px',
                        mb: 2,
                        border: selected
                          ? (theme) =>
                              `solid 1px ${theme.palette.secondary.main}`
                          : 'none',
                      }}
                      onClick={() => setPickedGoal(goal.goalId)}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          color="secondary"
                          value={goal.goalId}
                          onChange={(event) =>
                            setPickedGoal(event.target.value)
                          }
                          checked={selected}
                        />
                      }
                    >
                      <ListItemText
                        primary={`$${goal.amount}/monthly goal`}
                        secondary={goal.description}
                      />
                    </ListItem>
                  )
                })}
              </List>
              <Button
                variant="contained"
                fullWidth
                color={myCharity ? 'secondary' : 'primary'}
                sx={{ p: 2, color: '#fff', fontWeight: 'bold' }}
                startIcon={myCharity ? <AutorenewIcon /> : <FavoriteIcon />}
                onClick={setCharityGoal}
              >
                {myCharity ? 'Select Goal' : 'Select Charity & Goal'}
              </Button>
            </Box>
          </Drawer> */}
        </>
      )}
    </Box>
  );
};

export default CharityDetailsPage;
