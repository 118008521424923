import axios from 'axios'
import { AppUser } from '../types'
import { UserApiBasePath } from '../utils/UrlPaths'
import { User } from '@auth0/auth0-react'

type UpsertBasiqUserParams = {
  userId: string
}

export type BasiqUserMutationProps = {
  email: string
  fullName: string
  authUser: User
  token: string
}

export const upsertBasiqUserQuery = async ({
  email,
  fullName,
  authUser,
  token,
}: BasiqUserMutationProps) => {
  const userId = authUser.sub?.split('|')[1]

  const input = {
    userId,
    email,
    fullName,
  }

  const data = await axios.put<UpsertBasiqUserParams, AppUser>(
    `${UserApiBasePath}/createBasiqUser`,
    input,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
  return data
}
