import axios from 'axios'
import { UserApiBasePath } from '../utils/UrlPaths'

type BasiqJobsResponse = {
  data: BasiqJobResponse[]
}

type BasiqJobResponse = {
  id: string
  steps: JobStep[]
  updated: string
  created: string
}

type JobStep = {
  status: JobStepStatus
  title: JobStepTitle
}

export enum JobStepStatus {
  pending = 'pending',
  inProgress = 'in-progress',
  success = 'success',
  failed = 'failed',
}

export enum JobStepTitle {
  verifyCredentials = 'verify-credentials',
  retrieveAccounts = 'retrieve-accounts',
  retrieveTransactions = 'retrieve-transactions',
  retrieveStatements = 'retrieve-statements',
}

export const fetchBasiqJob = async (jobId: string, token: string) => {
  //console.log(`fetching for: ${jobId}`)
  const { data } = await axios.get<BasiqJobResponse>(
    encodeURI(`https://au-api.basiq.io/jobs/${jobId}`),
    {
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
      },
    },
  )

  //console.log(data)
  return data
}

export const fetchBasiqJobsForUser = async (
  basiqUserId: string,
  token: string,
) => {
  const { data } = await axios.get<BasiqJobsResponse>(
    `${UserApiBasePath}/fetchJobs/${basiqUserId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

  return data.data
}
