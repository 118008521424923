import {
  Avatar,
  Icon,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { CharityImpact } from '../types'

interface ImpactItemProps {
  impact: CharityImpact
}

const ImpactItem = ({ impact }: ImpactItemProps) => (
  <ListItem
    sx={{
      background: `linear-gradient(
        171.71deg,
        rgba(255, 255, 255, 0.93) 10.18%,
        rgba(255, 255, 255, 0.773779) 32.87%,
        rgba(255, 255, 255, 0.55) 62.73%,
        rgba(255, 255, 255, 0.78) 89.42%
      ),
      linear-gradient(90deg, #9852fc 0%, #fc55d0 33%, #ff8b65 66%, #ffeb2b 100%)`,
      borderRadius: '16px',
      mb: 2,
      p: 2,
    }}
  >
    <ListItemAvatar>
      <Avatar
        sx={{
          bgcolor: '#fff',
          fontWeight: 'bold',
        }}
      >
        <Icon
          baseClassName="fas"
          className={impact.icon}
          sx={{ color: (theme) => theme.palette.secondary.main }}
        />
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={impact.description} />
  </ListItem>
)

export default ImpactItem
