import axios from 'axios'
import { AppUser } from '../types'
import { UserApiBasePath } from '../utils/UrlPaths'
import { User } from '@auth0/auth0-react'

type UpdateUserParams = {
  userId: string
  user: AppUser
}

export type UpdateUserQueryProps = {
  user: AppUser
  authUser: User
  token: string
}

export const updateUserQuery = async ({
  user,
  authUser,
  token,
}: UpdateUserQueryProps) => {
  const userId = authUser.sub?.split('|')[1]

  const input = {
    userId,
    user,
  }

  const data = await axios.put<UpdateUserParams, AppUser>(
    `${UserApiBasePath}`,
    input,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
  return data
}
