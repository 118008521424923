import { AppBar, Card, CardContent } from '@mui/material'
import React, { ReactNode } from 'react'
import NavButton from './NavButton'

const NavFooter: React.FC<ReactNode> = () => (
  <AppBar
    position="fixed"
    color="primary"
    sx={{
      top: 'auto',
      bottom: 0,
      background: 'transparent',
      alignItems: 'center',
    }}
    elevation={0}
  >
    <Card
      elevation={3}
      sx={{
        width: { sm: '600px', xs: '100%' },
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <NavButton title="Home" />
        <NavButton title="Donations" />
        <NavButton title="Charities" />
        <NavButton title="Profile" />
      </CardContent>
    </Card>
  </AppBar>
)

export default NavFooter
