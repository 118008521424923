import { createTheme } from '@mui/material/styles'

export const appTheme = createTheme({
  palette: {
    primary: {
      main: '#9852FC',
    },
    secondary: {
      main: '#FF8B65',
    },
    text: {
      primary: '#1B1C1D',
      disabled: '#ADB1B5',
      secondary: '#6C7074',
    },
  },
  shape: {
    borderRadius: 16,
  },
  typography: {
    fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
})
