import { useQuery } from 'react-query'
import axios from 'axios'
import { AppUser } from '../types'
import { UserApiBasePath } from '../utils/UrlPaths'
import { useAuth0, User } from '@auth0/auth0-react'

const fetchUser = async (getToken: Promise<string>, user: User) => {
  const token = await getToken

  if (user.sub) {
    const userId = user.sub.split('|')[1]
    const { data } = await axios.get<AppUser>(`${UserApiBasePath}/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
    return data
  }
}

const useUser = (retry = true) => {
  const { user, getAccessTokenSilently } = useAuth0()

  const getToken = getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
    },
  })

  return useQuery('users', () => fetchUser(getToken, user!), {
    //staleTime: 1000 * 60 * 60 * 12,
    enabled: !!user,
    retry,
  })
}
export default useUser
