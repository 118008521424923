import {
  Card,
  CardContent,
  CardMedia,
  Modal,
  Stack,
  IconButton,
  Typography,
  Tooltip,
  Zoom,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import { Box } from '@mui/system'

import { AppUser, CharityDetail } from '../types'
import CharityLogo from './CharityLogo'
import GradientDivider from './GradientDivider'
import { useEffect, useState } from 'react'

type DonateNowModalProps = {
  open: boolean
  charity: CharityDetail
  user: AppUser
  handleClose: () => void
  handleMultiplierUpdate: (multi: number) => void
  handleSwitchToBasiq: () => void
}

const multiples = [1, 2, 3, 4]

const DonateNowModal = (props: DonateNowModalProps) => {
  const {
    open,
    handleClose,
    charity,
    user,
    handleMultiplierUpdate,
    handleSwitchToBasiq,
  } = props
  const [multi, setMulti] = useState(1)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setMulti(user.roundupMultiplier)
  }, [user])

  const handleUpdate = () => {
    handleMultiplierUpdate(multi)
    handleClose()
  }

  const basiqConfigured = user.basiqUserId && user.accounts?.length

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: isSmallScreen ? 'flex-start' : 'center',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <Card sx={{ maxWidth: 400, position: 'relative' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: -1,
              right: -1,
              backgroundColor: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardMedia
            component="img"
            image={`${process.env.PUBLIC_URL}/images/${charity.media.header}`}
            sx={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}
          />
          <CardContent sx={{ p: 3 }}>
            <Stack spacing={isSmallScreen ? 1 : 3}>
              <CharityLogo charity={charity} />
              <GradientDivider />
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                Round up
                {!isSmallScreen && (
                  <Tooltip
                    TransitionComponent={Zoom}
                    placement="top"
                    title="The round up multiplier allows you to double, triple or
                even quadruple each round up donation. For example a
                $4.50 purchase at 2x multiplier will take the round up
                donation from 50c to $1."
                  >
                    <InfoIcon sx={{ ml: 1, fontSize: '16px' }} />
                  </Tooltip>
                )}
                {isSmallScreen && (
                  <Typography
                    variant="body2"
                    sx={{ marginTop: '-6px', fontSize: '0.75rem' }}
                  >
                    The round up multiplier allows you to double, triple or even
                    quadruple each round up donation. For example a $4.50
                    purchase at 2x multiplier will take the round up donation
                    from 50c to $1.
                  </Typography>
                )}
              </Typography>
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ mt: isSmallScreen ? 1 : 2 }}>
                  Your current round up multiplier
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {multiples.map((m) => (
                  <Button
                    variant={m === multi ? 'contained' : 'outlined'}
                    key={m}
                    onClick={() => setMulti(m)}
                    sx={{ px: 4, py: 2 }}
                  >
                    {m}
                  </Button>
                ))}
              </Box>
              <Button
                sx={{ px: 5, py: 2 }}
                variant="contained"
                disabled={multi === user.roundupMultiplier}
                onClick={handleUpdate}
              >
                Update multiplier
              </Button>
              {!basiqConfigured && (
                <Button
                  sx={{ px: 5, py: 2, color: 'white' }}
                  variant="contained"
                  color="secondary"
                  onClick={handleSwitchToBasiq}
                >
                  Setup round up
                </Button>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  )
}

export default DonateNowModal
