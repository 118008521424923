import { DonationHistory } from '../types'
import * as fns from 'date-fns'

export const getDonationHistoryForDate = (
  donationHistory: DonationHistory | undefined,
  date: Date,
) => {
  const year = fns.getYear(date)
  const month = fns.getMonth(date) + 1
  if (donationHistory) {
    if (donationHistory[year]) {
      if (donationHistory[year][month]) {
        // @ts-ignore
        return parseFloat(donationHistory[year][month])
      }
    }
  }
  return 0
}

export const getDonationHistoryForYear = (
  donationHistory: DonationHistory | undefined,
  year: number,
) => {
  if (donationHistory) {
    if (
      donationHistory[year] &&
      Object.keys(donationHistory[year]).length > 0
    ) {
      const months = Object.keys(donationHistory[year])
      const totalForYear = months.reduce((acc, month) => {
        // @ts-ignore
        return acc + parseFloat(donationHistory[year][month])
      }, 0)
      return totalForYear
    }
  }

  return 0
}

// export const zeroPad = (num: number, places = 2): string =>
//   String(num).padStart(places, '0')
