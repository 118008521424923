import { Box, IconButton } from '@mui/material'
import { CharitySocial } from '../types'

import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'

interface CharitySocialGroupProps {
  social: CharitySocial
}

const CharitySocialGroup = ({ social }: CharitySocialGroupProps) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
    {social.facebook && (
      <IconButton
        size="large"
        onClick={() => window.open(social.facebook, '_blank')!.focus()}
      >
        <FacebookIcon />
      </IconButton>
    )}
    {social.instagram && (
      <IconButton
        size="large"
        onClick={() => window.open(social.instagram, '_blank')!.focus()}
      >
        <InstagramIcon />
      </IconButton>
    )}
    {social.twitter && (
      <IconButton
        size="large"
        onClick={() => window.open(social.twitter, '_blank')!.focus()}
      >
        <TwitterIcon />
      </IconButton>
    )}
    {social.linkedin && (
      <IconButton
        size="large"
        onClick={() => window.open(social.linkedin, '_blank')!.focus()}
      >
        <LinkedInIcon />
      </IconButton>
    )}
  </Box>
)

export default CharitySocialGroup
