import { Card, CardContent, Box } from "@mui/material";
import { SustainableDevelopmentGoal } from "../types";

interface UNGoalsCardProps {
  ungoals: SustainableDevelopmentGoal[];
}

const UNGoalsCard = ({ ungoals }: UNGoalsCardProps) => (
  <Card>
    <CardContent
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/UNGoals/GoalLogo.png`}
        style={{ maxWidth: "60%" }}
        alt="UN Sustainability Goals Logo"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          pt: 4,
        }}
      >
        {ungoals.map((goal, i) => (
          <a
            href={goal.goalLink}
            key={i}
            target="_blank"
            rel="noreferrer"
            style={{ maxWidth: "30%" }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/UNGoals/Icons_WEB-${goal.goalNumber}.png`}
              style={{ maxWidth: "100%" }}
              alt={`UN Sustainablity Goal ${goal.goalNumber} icon.`}
            />
          </a>
        ))}
      </Box>
    </CardContent>
  </Card>
);

export default UNGoalsCard;
