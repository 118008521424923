import { Card, CardContent, LinearProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'

interface MyGoalProgressProps {
  progress: number
  goal: string
  amount: number
}

const MyGoalProgress = ({ progress, goal, amount }: MyGoalProgressProps) => {
  return (
    <Card sx={{ mt: 4 }}>
      <CardContent sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" color="primary">
            Your Impact
          </Typography>
          <Typography variant="h6">{progress.toFixed(0)}%</Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={Math.min(100, progress)}
          color="primary"
          sx={{ mt: 1 }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Typography variant="body1">{goal}</Typography>
          <Typography variant="h6" color="primary">
            ${amount}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default MyGoalProgress
