import axios from 'axios'
import { AppUser } from '../types'
import { UserApiBasePath } from '../utils/UrlPaths'
import { User } from '@auth0/auth0-react'

type UpdateUserParams = {
  accountId: string
}

export type UpdateUserDonationQueryProps = {
  accountId: string
  authUser: User
  token: string
}

export const updateUserDonationQuery = async ({
  accountId,
  authUser,
  token,
}: UpdateUserDonationQueryProps) => {
  const userId = authUser.sub?.split('|')[1]

  const input = {
    accountId,
  }

  const data = await axios.put<UpdateUserParams, AppUser>(
    `${UserApiBasePath}/${userId}/dispersment`,
    input,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
  return data
}
