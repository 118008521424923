import { Paper, ToggleButtonGroup } from '@mui/material'
import React, { PropsWithChildren, ReactNode } from 'react'

import { styled } from '@mui/material/styles'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    textTransform: 'capitalize',
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      backgroundColor: '#fff',
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

type PillToggleProps = PropsWithChildren<ReactNode> & {
  value: string
  handleChange: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: any,
  ) => void
}

const PillToggleButtonGroup = ({
  children,
  value,
  handleChange,
}: PillToggleProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
        background: 'transparent',
        mt: 2,
      }}
    >
      <StyledToggleButtonGroup
        size="small"
        value={value}
        exclusive
        onChange={handleChange}
      >
        {children}
      </StyledToggleButtonGroup>
    </Paper>
  )
}

export default PillToggleButtonGroup
