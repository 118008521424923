import { Container } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

const AppLayout: React.FC<ReactNode> = () => {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Container>
  )
}

export default AppLayout
