import { Box } from '@mui/system'
import { CharityDetail } from '../types'

type CharityLogoProps = {
  charity: CharityDetail
  verticalLogoStyles?: React.CSSProperties
  logoStyles?: React.CSSProperties
}

const CharityLogo = ({
  charity,
  verticalLogoStyles,
  logoStyles,
}: CharityLogoProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    {charity.verticalLogo ? (
      <img
        src={`${process.env.PUBLIC_URL}/images/${charity.media.logo}`}
        style={verticalLogoStyles ?? { height: 140, marginTop: -120 }}
        alt="charity logo"
      />
    ) : (
      <img
        src={`${process.env.PUBLIC_URL}/images/${charity.media.logo}`}
        style={logoStyles ?? { maxWidth: '60%' }}
        alt="charity logo"
      />
    )}
  </Box>
)

export default CharityLogo
