import { useQuery } from 'react-query'
import axios from 'axios'
import { UserApiBasePath } from '../utils/UrlPaths'
import { useAuth0 } from '@auth0/auth0-react'
import useUser from './useUser'
import { AppUser } from '../types'

type TokenResponse = {
  access_token: string
  token_type: string
  expires_in: number
}

const fetchToken = async (getToken: Promise<string>, user: AppUser) => {
  const token = await getToken
  const { data } = await axios.get<TokenResponse>(
    `${UserApiBasePath}/basiqToken/${user.basiqUserId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )

  return data.access_token
}

const useBasiqToken = () => {
  const { getAccessTokenSilently } = useAuth0()
  const { data: user } = useUser()

  const getToken = getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
    },
  })

  return useQuery('basiqToken', () => fetchToken(getToken, user!), {
    //staleTime: 1000 * 60 * 20, // 20 min
    //cacheTime: 1000 * 60 * 20,
    enabled: !!user && !!user.basiqUserId,
  })
}

export default useBasiqToken
