import { Card, CardContent, Modal, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { PropsWithChildren, ReactNode } from 'react'

type CentralModalProps = PropsWithChildren<ReactNode> & {
  open: boolean
  handleClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
}

const CentralModal = ({ open, handleClose, children }: CentralModalProps) => (
  <Modal open={open} onClose={handleClose}>
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card>
        <CardContent sx={{ p: 3 }}>
          <Stack spacing={3}>{children}</Stack>
        </CardContent>
      </Card>
    </Box>
  </Modal>
)

export default CentralModal
