import { ListItem, ListItemText } from '@mui/material'

interface TransactionDividerProps {
  date: string
}

const TransactionDivider = ({ date }: TransactionDividerProps) => (
  <ListItem
    sx={{
      backgroundColor: (theme) => theme.palette.divider,
      borderRadius: '16px',
      mb: 2,
    }}
  >
    <ListItemText primary={date} />
  </ListItem>
)

export default TransactionDivider
