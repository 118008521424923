import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from './components/ProtectedRoute'
import AppLayout from './layouts/AppLayout'
import PublicLayout from './layouts/PublicLayout'
import CallbackPage from './pages/CallbackPage'
import CharitiesPage from './pages/CharitiesPage'
import CharityDetailsPage from './pages/CharityDetailsPage'
import DonationsPage from './pages/DonationsPage'
import HomePage from './pages/HomePage'
import ProfilePage from './pages/ProfilePage'

function App() {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/callback" element={<CallbackPage />} />
      </Route>
      <Route element={<AppLayout />}>
        <Route path="/home" element={<ProtectedRoute component={HomePage} />} />
        <Route
          path="/donations"
          element={<ProtectedRoute component={DonationsPage} />}
        />
        <Route
          path="/charities"
          element={<ProtectedRoute component={CharitiesPage} />}
        />
        <Route
          path="/charities/:charityId"
          element={<ProtectedRoute component={CharityDetailsPage} />}
        />
        <Route
          path="/profile"
          element={<ProtectedRoute component={ProfilePage} />}
        />
      </Route>
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  )
}

export default App
