import { Avatar } from '@mui/material'

import { AppUser } from '../types'

interface C4cAvatarProps {
  user: AppUser
  height?: number
  width?: number
}

const C4cAvatar = ({ user, height, width }: C4cAvatarProps) =>
  !user.avatarUrl ? (
    <Avatar
      id="avatar-upload-trigger"
      variant="rounded"
      sx={{
        height: height ?? 56,
        width: height ?? 56,
        bgcolor: 'primary.main',
      }}
    >
      {user.fullName?.split('')[0] || 'U'}
    </Avatar>
  ) : (
    <Avatar
      id="avatar-upload-trigger"
      variant="rounded"
      sx={{
        height: height ?? 56,
        width: width ?? 56,
        bgcolor: 'primary.main',
      }}
      src={user.avatarUrl}
    />
  )

export default C4cAvatar
