import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

const Auth0ProviderWithCallback = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()
  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || '/home')
  }

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      authorizationParams={{
        redirectUri: window.location.origin + '/callback',
        scope: 'openid profile email',
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithCallback
