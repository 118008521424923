import { Box, Button, Container, List, Typography } from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { Fragment, useState } from 'react'
import NavFooter from '../components/NavFooter'
import ScreenHeader from '../components/ScreenHeader'
import useTransactions from '../hooks/useTransactions'
import * as fns from 'date-fns'
import TransactionDivider from '../components/TransactionDivider'
import TransactionItem from '../components/TransactionItem'
import useUser from '../hooks/useUser'
import BasiqModal from '../components/BasiqModal'
import BasiqJobHandler from '../components/BasiqJobHandler'
import useUserRefresh from '../hooks/useUserRefresh'

const DonationsPage: React.FC = () => {
  const [basiqModalOpen, setBasiqModalOpen] = useState(false)
  const handleBasiqModalClose = () => setBasiqModalOpen(false)
  useUserRefresh()

  const { data: rawTransactions, isLoading } = useTransactions()
  const { data: user } = useUser()
  const transactions = rawTransactions
    ?.sort((a, b) => {
      const aDate = fns.getTime(new Date(a.postDate))
      const bDate = fns.getTime(new Date(b.postDate))
      return aDate - bDate
    })
    .reverse()
  return (
    <Box sx={{ pb: 12 }}>
      <ScreenHeader title="Donations" />
      <Container maxWidth="sm" sx={{ p: { xs: 0 } }}>
        <Box>
          <Typography variant="h5">Recent Transactions</Typography>
          {!isLoading && transactions && (
            <List>
              {transactions
                .filter((t) => t.roundUp !== '0.00')
                .map((transaction, index, allTransactions) => {
                  const posted = new Date(transaction.postDate)
                  let showDivider = false

                  if (index === 0) {
                    showDivider = true
                  } else {
                    const lastDate = new Date(
                      allTransactions[index - 1].postDate,
                    )
                    if (!fns.isSameMonth(posted, lastDate)) {
                      showDivider = true
                    }
                  }
                  return (
                    <Fragment key={index}>
                      {showDivider && (
                        <TransactionDivider
                          date={fns.format(posted, 'MMM yyy')}
                        />
                      )}
                      <TransactionItem transaction={transaction} />
                    </Fragment>
                  )
                })}
            </List>
          )}
          {user && !user.basiqUserId && (
            <>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Looks like you have not connected any accounts yet, connect your
                accounts below to start making a difference!
              </Typography>
              <Button
                sx={{ px: 5, py: 2, mt: 3 }}
                variant="contained"
                startIcon={<AccountBalanceWalletIcon />}
                onClick={() => setBasiqModalOpen(true)}
              >
                Connect Accounts
              </Button>
            </>
          )}
        </Box>
      </Container>
      <NavFooter />
      {user && (
        <BasiqModal
          user={user}
          open={basiqModalOpen}
          handleClose={handleBasiqModalClose}
        />
      )}
      <BasiqJobHandler />
    </Box>
  )
}

export default DonationsPage
