import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType } from 'react'

interface ProtectedRouteProps {
  component: ComponentType
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
}) => {
  const myCharity = new URLSearchParams(window.location.search).get('myCharity')
  if (myCharity) {
    localStorage.setItem('myCharity', myCharity)
  }

  const Component = withAuthenticationRequired(component, {
    loginOptions: {
      appState: {
        myCharity,
      },
      authorizationParams: {
        screen_hint: 'signup',
      },
    },
  })

  return <Component />
}
