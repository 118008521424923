import { useQuery } from 'react-query'
import axios from 'axios'
import { CharityDetail } from '../types'
import { CharitiesApiBasePath } from '../utils/UrlPaths'
import { useAuth0 } from '@auth0/auth0-react'

type CharitiesResponse = {
  Count: number
  Items: CharityDetail[]
}

const fetchCharities = async (getToken: Promise<string>) => {
  const token = await getToken
  const { data } = await axios.get<CharitiesResponse>(
    `${CharitiesApiBasePath}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
  return data.Items
}

const useCharities = () => {
  const { getAccessTokenSilently } = useAuth0()

  const getToken = getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_CHARITY_API_AUDIENCE,
    },
  })

  return useQuery('charities', () => fetchCharities(getToken), {
    staleTime: 1000 * 60 * 60 * 12,
  })
}

export default useCharities
