import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { InitialUser } from '../utils/InitialUser'
import useUser from './useUser'
import { updateUserQuery, UpdateUserQueryProps } from './useUserMutation'

const useUserRefresh = () => {
  const { user: authUser, getAccessTokenSilently } = useAuth0()
  const { data: user, isLoading, isError } = useUser(false)
  const queryClient = useQueryClient()
  const useUserMutation = useMutation(
    ({ user, authUser, token }: UpdateUserQueryProps) =>
      updateUserQuery({ user, authUser, token }),
    {
      onSuccess: () => queryClient.invalidateQueries('users'),
    },
  )

  useEffect(() => {
    if (authUser && !isLoading) {
      ;(async () => {
        const userRefreshed = sessionStorage.getItem('userRefreshed')
        if (!userRefreshed || userRefreshed !== authUser.sub) {
          console.log('running user refresh')
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
            },
          })

          const userId = authUser.sub?.split('|')[1] ?? ''
          const provider = authUser.sub?.split('|')[0]
          const myCharity = localStorage.getItem('myCharity')

          useUserMutation.mutate({
            user: {
              ...InitialUser,
              userId,
              ...(!isError ? user : {}),
              provider,
              ...(provider !== 'auth0'
                ? {
                    fullName: `${authUser.given_name} ${authUser.family_name}`,
                    avatarUrl: authUser.picture,
                    ...(authUser.email ? { email: authUser.email } : {}),
                  }
                : {}),
              ...(!isError && !user?.myCharity && myCharity
                ? { myCharity }
                : {}),
            },
            authUser,
            token,
          })
        }
        sessionStorage.setItem('userRefreshed', `${authUser.sub}`)
      })()
    }
  }, [
    user,
    isLoading,
    isError,
    authUser,
    getAccessTokenSilently,
    useUserMutation,
  ])
}

export default useUserRefresh
