import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material'
import { appTheme } from './utils/ThemeWrapper'
import Auth0ProviderWithCallback from './components/Auth0ProviderWithCallback'
import { BrowserTracing } from '@sentry/react'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn:
    'https://9df12e88d23b4f68b65e250b623cdd3c@o4504887073964032.ingest.sentry.io/4504887078486016',
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.2,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.2,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Auth0ProviderWithCallback>
            <App />
          </Auth0ProviderWithCallback>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
