import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Transaction } from '../types'
import * as fns from 'date-fns'

interface TransactionItemProps {
  transaction: Transaction
}

const TransactionItem = ({ transaction }: TransactionItemProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  return (
    <ListItem
      sx={{
        backgroundColor: '#fff',
        borderRadius: '16px',
        mb: 2,
        pl: isMobile ? 1 : 2,
      }}
      secondaryAction={
        <Typography
          sx={{ fontWeight: 'bold', fontSize: isMobile ? '1.1rem' : '1.2rem' }}
          variant="h6"
        >
          ${transaction.roundUp}
        </Typography>
      }
    >
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: '#fff',
            fontWeight: 'bold',
            color: theme.palette.secondary.main,
            minWidth: isMobile ? '42px' : '56px',
          }}
        >
          x{transaction.multiplier}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{ maxWidth: isMobile ? '75%' : '85%' }}
        primaryTypographyProps={{
          sx: { fontSize: isMobile ? '0.8rem' : '1rem' },
        }}
        primary={transaction.description}
        secondaryTypographyProps={{
          sx: { fontSize: isMobile ? '0.65rem' : '0.875rem' },
        }}
        secondary={fns.format(new Date(transaction.postDate), 'do MMM yyy')}
      />
    </ListItem>
  )
}

export default TransactionItem
