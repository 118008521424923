import { useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

const PublicLayout: React.FC<ReactNode> = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: isSmallScreen ? 'flex-start' : 'center',
        justifyContent: 'center',
      }}
    >
      <Outlet />
    </Box>
  )
}

export default PublicLayout
