import {
  Box,
  Grid,
  ToggleButton,
  Typography,
  Button,
  Skeleton,
  Card,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import React, { useState } from "react";
import NavFooter from "../components/NavFooter";
import ScreenHeader from "../components/ScreenHeader";
import useCharities from "../hooks/useCharities";
import useTransactions from "../hooks/useTransactions";
import useUser from "../hooks/useUser";
import PillToggleButtonGroup from "../components/PillToggleButtonGroup";
import MyCharityCard from "../components/MyCharityCard";
import MyGoalProgress from "../components/MyGoalProgress";
import NoCharityCard from "../components/NoCharityCard";
import * as fns from "date-fns";
import DonateNowModal from "../components/DonateNowModal";
import { useMutation, useQueryClient } from "react-query";
import {
  updateUserQuery,
  UpdateUserQueryProps,
} from "../hooks/useUserMutation";
import BasiqModal from "../components/BasiqModal";
import BasiqJobHandler from "../components/BasiqJobHandler";
import {
  getDonationHistoryForDate,
  getDonationHistoryForYear,
} from "../utils/DonationHistory";
import C4cAvatar from "../components/C4cAvatar";
import { useAuth0 } from "@auth0/auth0-react";
import useUserRefresh from "../hooks/useUserRefresh";
import OnboardingCard from "../components/OnboardingCard";

const HomePage: React.FC = () => {
  const { user: authUser, getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const useUserMutation = useMutation(
    ({ user, authUser, token }: UpdateUserQueryProps) =>
      updateUserQuery({ user, authUser, token }),
    {
      onSuccess: () => queryClient.invalidateQueries("users"),
    }
  );

  const handleDonationMultiplierUpdate = async (multi: number) => {
    if (user && !userLoading && authUser) {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_USER_API_AUDIENCE,
        },
      });
      useUserMutation.mutate({
        user: {
          ...user,
          roundupMultiplier: multi,
        },
        authUser,
        token,
      });
    }
  };

  const [donateOpen, setDonateOpen] = useState(false);
  const [basiqOpen, setBasiqOpen] = useState(false);
  const [timeFrame, setTimeFrame] = useState("month");
  const monthly = timeFrame === "month";
  const yearly = timeFrame === "year";

  useUserRefresh();
  const { data: user, isLoading: userLoading } = useUser();
  const { data: charities, isLoading: charitiesLoading } = useCharities();
  const { data: transactions, isLoading: transactionsLoading } =
    useTransactions();

  const monthDonations = getDonationHistoryForDate(
    user?.donationHistory,
    new Date()
  );

  const yearlyDonations = getDonationHistoryForYear(
    user?.donationHistory,
    fns.getYear(new Date())
  );

  const weekDonations =
    transactions
      ?.filter((t) =>
        fns.isAfter(new Date(t.postDate), fns.sub(new Date(), { weeks: 1 }))
      )
      .reduce((sum, { roundUp }) => sum + parseFloat(roundUp), 0) || 0;

  const myCharity = charities?.find((c) => c.charityId === user?.myCharity);
  const orderedGoals = myCharity?.goals?.sort((a, b) => a.amount - b.amount);
  const myGoal = orderedGoals?.find((g) => g.amount > monthDonations);
  const goalProgress =
    user && myGoal ? (monthDonations / myGoal.amount) * 100 : 0;
  const userFirstName = user?.fullName?.split(" ")[0];
  const userHasAccounts = (user?.accounts?.length ?? 0) > 0;
  const userHasCharity = user?.myCharity !== undefined;

  const handleTimeFrame = (
    _: React.MouseEvent<HTMLElement>,
    newTimeframe: string
  ) => {
    setTimeFrame(newTimeframe);
  };

  const handleBasiqOpen = () => {
    setBasiqOpen(true);
  };

  const TwoColContent = () => (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {user && <C4cAvatar user={user} height={100} width={100} />}
          {transactionsLoading ? (
            <Skeleton variant="rectangular" height={40} width={100} />
          ) : (
            <Typography variant="h4" fontWeight="bold" sx={{ mt: 4 }}>
              $
              {monthly
                ? monthDonations.toFixed(2)
                : yearly
                ? yearlyDonations.toFixed(2)
                : weekDonations.toFixed(2)}
            </Typography>
          )}

          <Typography variant="subtitle2" color="primary.main">
            Your Donations
          </Typography>
          <PillToggleButtonGroup
            value={timeFrame}
            handleChange={handleTimeFrame}
          >
            <ToggleButton value="week">week</ToggleButton>
            <ToggleButton value="month">month</ToggleButton>
            <ToggleButton value="year">year</ToggleButton>
          </PillToggleButtonGroup>
          {myCharity && (
            <Button
              sx={{ px: 5, py: 2, mt: 3 }}
              variant="contained"
              startIcon={<FavoriteIcon />}
              onClick={() => setDonateOpen(true)}
            >
              Round Up Multiplier
            </Button>
          )}
          {user && (!user.basiqUserId || !user.accounts) && (
            <Button
              sx={{ px: 5, py: 2, mt: 3, color: "white" }}
              variant="contained"
              color="secondary"
              startIcon={<AccountBalanceWalletIcon />}
              onClick={handleBasiqOpen}
            >
              Connect Accounts
            </Button>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        {charitiesLoading && (
          <Card sx={{ mb: 4 }}>
            <Skeleton variant="rectangular" height={250} />
          </Card>
        )}
        {!charitiesLoading && myCharity && (
          <MyCharityCard myCharity={myCharity} />
        )}
        {charitiesLoading && (
          <Card sx={{ mb: 2 }}>
            <Skeleton variant="rectangular" height={100} />
          </Card>
        )}
        {!charitiesLoading && myCharity && myGoal && (
          <MyGoalProgress
            progress={goalProgress}
            goal={myGoal.description}
            amount={myGoal.amount}
          />
        )}
        {!charitiesLoading && !myCharity && <NoCharityCard />}
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ pb: 12 }}>
      <ScreenHeader
        title={user?.fullName ? `Welcome, ${userFirstName}!` : "Welcome!"}
      />

      {userHasAccounts && userHasCharity ? (
        <TwoColContent />
      ) : (
        user && <OnboardingCard user={user} connectAccounts={handleBasiqOpen} />
      )}

      {myCharity && user && (
        <DonateNowModal
          open={donateOpen}
          handleClose={() => setDonateOpen(false)}
          handleMultiplierUpdate={handleDonationMultiplierUpdate}
          handleSwitchToBasiq={() => {
            setDonateOpen(false);
            setBasiqOpen(true);
          }}
          charity={myCharity}
          user={user}
        />
      )}

      {user && (
        <BasiqModal
          user={user}
          open={basiqOpen}
          handleClose={() => setBasiqOpen(false)}
        />
      )}

      <NavFooter />
      <BasiqJobHandler />
    </Box>
  );
};

export default HomePage;
