import { Box, Typography } from '@mui/material'

type ScreenHeaderProps = {
  title: string
}

const ScreenHeader: React.FC<ScreenHeaderProps> = ({ title }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pt: { xs: '1rem', md: '4rem' },
      pb: '3rem',
    }}
  >
    <img
      style={{ width: '80px' }}
      alt="Change 4 Change"
      src="https://images.squarespace-cdn.com/content/v1/61258dbc1dd61d0a911bd720/0fa4c0eb-b041-430d-af2a-6c8fe33c1bcd/Change-4-Change-hi-res+%283%29.png?format=80w"
    />
    <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', md: '2.25rem' } }}>
      {title}
    </Typography>
  </Box>
)

export default ScreenHeader
