import { Masonry } from '@mui/lab'
import { Box, Container, Typography } from '@mui/material'
import CharityCard from '../components/CharityCard'
import NavFooter from '../components/NavFooter'
import ScreenHeader from '../components/ScreenHeader'
import useCharities from '../hooks/useCharities'
import useUser from '../hooks/useUser'
import useUserRefresh from '../hooks/useUserRefresh'

const CharitiesPage: React.FC = () => {
  useUserRefresh()
  const { data: charities, isLoading } = useCharities()
  const { data: user, isLoading: userLoading } = useUser()
  const ready = !isLoading && !userLoading

  return (
    <Box sx={{ pb: 12 }}>
      <ScreenHeader title="Charities" />
      <Container maxWidth="sm" sx={{ p: { xs: 0 } }}>
        <Typography variant="body1" sx={{ mb: 4 }}>
          We carefully choose which organisations to support, handpicking an
          innovative group of charities that excel in their areas of impact.
          Stay tuned for more charities in the near future.
        </Typography>
        <Box>
          {ready && charities && (
            <Masonry columns={2} spacing={1}>
              {charities.map((charity, index) => (
                <CharityCard
                  key={index}
                  charity={charity}
                  isMyCharity={charity.charityId === user?.myCharity}
                />
              ))}
            </Masonry>
          )}
        </Box>
      </Container>
      <NavFooter />
    </Box>
  )
}

export default CharitiesPage
