import { User } from '@auth0/auth0-react'
import axios from 'axios'
import { AppUser } from '../types'
import { UserApiBasePath } from '../utils/UrlPaths'

type UpsertBasiqUserAccountsParams = {
  userId: string
}

export type BasiqUserAccountsMutationProps = {
  authUser: User
  token: string
}

export const upsertBasiqUserAccountsQuery = async ({
  authUser,
  token,
}: BasiqUserAccountsMutationProps) => {
  const userId = authUser.sub?.split('|')[1]

  const input = {
    userId,
  }

  const data = await axios.put<UpsertBasiqUserAccountsParams, AppUser>(
    `${UserApiBasePath}/fetchAccounts`,
    input,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  )
  return data
}
