import * as fns from 'date-fns'
import { AppUser } from '../types'
export const InitialUser: AppUser = {
  userId: '',
  email: '',
  fullName: '',
  basiqUserId: undefined,
  coverTransferCosts: false,
  donationsPaused: false,
  donationsPausedTill: '',
  allowNotifications: true,
  roundupMultiplier: 1,
  roundUps: 0,
  thisMonthDonations: 0,
  allTimeDonations: 0,
  donationCap: 500.0,
  myCharity: '',
  charityGoal: '',
  joined: fns.formatISO(new Date(), { representation: 'date' }),
  wholeDollarRoundups: false,
}
