import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { Link } from "react-router-dom";

interface NavButtonProps {
  title: string;
}

const NavButton = ({ title }: NavButtonProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isSelected = window.location.pathname === `/${title.toLowerCase()}`;

  return (
    <Link
      to={`/${title.toLowerCase()}`}
      style={{
        textDecoration: "none",
        padding: isMobile ? "0.5rem 0.5rem" : "0.5rem 1rem",
        color: isSelected
          ? theme.palette.secondary.main
          : theme.palette.text.primary,
        //@ts-ignore
        fontFamily: theme.typography.fontFamily,
        fontWeight: "bold",
      }}
    >
      {title}
    </Link>
  );
};

export default NavButton;
