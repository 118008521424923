import { useQuery } from 'react-query'
import axios from 'axios'
import { Transaction } from '../types'
import { TransactionsApiBasePath } from '../utils/UrlPaths'
import { useAuth0, User } from '@auth0/auth0-react'

type TransactionResponse = {
  Count: number
  Items: Transaction[]
}

const fetchTransactions = async (getToken: Promise<string>, user: User) => {
  const token = await getToken

  if (user.sub) {
    const userId = user.sub.split('|')[1]
    const { data } = await axios.get<TransactionResponse>(
      `${TransactionsApiBasePath}/${userId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    )
    return data.Items
  }
}

const useTransactions = () => {
  const { user, getAccessTokenSilently } = useAuth0()

  const getToken = getAccessTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_TRANSACTION_API_AUDIENCE,
    },
  })
  return useQuery('transactions', () => fetchTransactions(getToken, user!), {
    staleTime: 1000 * 60 * 60 * 4,
    enabled: !!user,
  })
}
export default useTransactions
