import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

const NoCharityCard = () => {
  const nav = useNavigate()
  const handleClick = () => {
    nav('/charities')
  }
  return (
    <Card>
      <CardContent sx={{ p: 4 }}>
        <Stack spacing={4}>
          <Typography variant="h6">
            Choose your charity to start making a difference!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ px: 5, py: 2, mt: 3 }}
            onClick={handleClick}
          >
            Search Charities
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default NoCharityCard
