import { Card, CardContent, CardMedia, Fab } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { CharityDetail } from '../types'
import { useNavigate } from 'react-router'
import CharityLogo from './CharityLogo'

interface CharityCardProps {
  charity: CharityDetail
  isMyCharity: boolean
}

const CharityCard = ({ charity, isMyCharity }: CharityCardProps) => {
  const nav = useNavigate()
  const handleClick = () => {
    nav(`/charities/${charity.charityId}`)
  }
  return (
    <Card onClick={handleClick} sx={{ minHeight: 150 }}>
      <CardMedia
        component="img"
        image={`${process.env.PUBLIC_URL}/images/${charity.media.header}`}
      />

      <CardContent sx={{ position: 'relative', p: 3 }}>
        {isMyCharity && (
          <Fab color="secondary" sx={{ position: 'absolute', top: -35 }}>
            <FavoriteIcon sx={{ color: '#fff' }} />
          </Fab>
        )}
        <CharityLogo charity={charity} />
      </CardContent>
    </Card>
  )
}

export default CharityCard
